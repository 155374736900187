<template>
  <div>
    <CToaster position="top-left" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast
            :key="'toast' + toast.text + index"
            :show="true"
            :header="toast.header === '' ? null : toast.header"
        >
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CRow>
      <CCol class="col-6">
        <CCard>
          <CCardHeader class="font-weight-bold">Başlık ve Slug Düzenleme</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CInput
                    label="Slug"
                    v-model="slug"
                >
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="d-flex justify-content-end">
                <CButton v-if="showSlugSaveButton" color="success" @click="updateSlug">
                  Kaydet
                </CButton>
              </CCol>
            </CRow>
            <CRow alignVertical="center">
              <CCol col="12">
                <CInput
                    label="Başlık"
                    v-model="title"
                >
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="d-flex justify-content-end">
                <CButton v-if="showTitleSaveButton" color="success" @click="updateTitle">
                  Kaydet
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <strong>İçerik Düzenleme</strong>
          </CCardHeader>
          <CCardBody>
            <v-md-editor v-model="content"/>
          </CCardBody>
          <CButton v-if="showContentSaveButton" color="success" @click="updateContent">
            İçeriği Kaydet
          </CButton>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {LegalDocumentAdministration} from "@/services/api-service";
import striptags from 'striptags'

Vue.use(VueQuillEditor)
export default {
  name: "EditLegalDocuments",
  mounted() {
    this.loadDocument();
  },
  data() {
    return {
      slug: '',
      slugFromServer: '',
      isDataLoading: false,
      titleFromServer: '',
      title: '',
      contentFromServer: '',
      content: '',
      toasts: []
    }
  },
  computed: {
    showSlugSaveButton() {
      return this.slug !== this.slugFromServer
    },
    showTitleSaveButton() {
      return this.title !== this.titleFromServer;
    },
    showContentSaveButton() {
      return this.contentFromServer !== striptags(this.content);
    }
  },
  methods: {
    async loadDocument() {
      const id = parseInt(this.$route.params['id']);

      const result = await LegalDocumentAdministration.getAllLegalDocumentsForAdministration();
      const legalDocument = result.data.legalDocuments.find(f => f.id === id);

      this.titleFromServer = legalDocument.title;
      this.title = legalDocument.title

      this.contentFromServer = legalDocument.content;
      this.content = legalDocument.content;

      this.slug = legalDocument.slug;
      this.slugFromServer = legalDocument.slug;
    },
    updateSlug() {
      this.isDataLoading = true;
      const id = parseInt(this.$route.params['id']);
      try {
        LegalDocumentAdministration.changeSlugOfTheLegalDocument(id, this.slug)
        this.slugFromServer = this.slug
        this.$toast.success("İşlem Başarılı! Döküman Slug'ı Başarıyla Değiştirildi.")
      } catch (e) {
        this.toasts.push("Veri Değiştirilemedi", "Hata")
      }
      this.isDataLoading = false;
    },
    async updateTitle() {
      this.isDataLoading = true;
      const id = parseInt(this.$route.params['id']);
      try {
        await LegalDocumentAdministration.changeTitleOfTheLegalDocument(id, this.title);
        this.titleFromServer = this.title;
        this.$toast.success("İşlem Başarılı! Döküman Başlığı Başarıyla Değiştirildi.")

      } catch (e) {
        this.toasts.push("Veri Güncellenemedi", "Hata")
      }
      this.isDataLoading = false;
    },
    async updateContent() {
      this.isDataLoading = true;
      const id = parseInt(this.$route.params['id']);
      try {
        await LegalDocumentAdministration.changeContentOfTheLegalDocument(id, this.content)
        this.contentFromServer = striptags(this.content);
        this.$toast.success("İşlem Başarılı! Döküman İçeriği Başarıyla Değiştirildi.")

      } catch (e) {
        this.toasts.push("Veri Güncellenemedi", "Hata")
      }
      this.isDataLoading = false;
    },
    showAlert() {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
              'Silindi!',
              'İşlem Başarıyla Gerçekleştirildi!',
              'success'
          )
        }
      });
    },
    showToast: function (text = 'Değişiklikler Başarıyla Kaydedildi', header = 'Bilgi') {
      this.toasts.push({text, header});
    }
  }
}
</script>

